// Guards //
import guards from './guards';

// Layout //
const Layout = () => import('../layouts/ShopAdmin/ShopAdminDashboardLayout');

// Default //
const Home = () => import('../views/ShopAdmin/ShopHome');

// custom //
// const ShopDetails = () => import('../views/ShopAdmin/ShopDetails');
const UserDetails = () => import('../views/SuperAdmin/User/UserDetails');
const ShopCatalog = () => import('../views/ShopAdmin/ShopCatalog/ShopCatalog');
const Orders = () => import('../views/ShopAdmin/Orders/Orders');
const OrderDetails = () => import('../views/ShopAdmin/Orders/OrderDetails');

const ShopDetails = () => import('../views/ShopAdmin/UserAndShopDetails');

export default {
    path: '/shop-admin/',
    name: 'ShopAdminDashboardLayout',
    redirect: '/shop-admin/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/shop-admin/',
            name: 'Home',
            component: Home
        },
        {
            path: '/shop-admin/:id/details/',
            name: 'ShopDetails',
            component: ShopDetails
        },
        {
            path: '/shop-admin/user/details/',
            name: 'UserDetails',
            component: UserDetails
        },

        // {
        //     path: '/shop-admin/:id/',
        //     name: 'ShopDetails',
        //     component: ShopDetails
        // },

        {
            path: '/shop-admin/products/:id/',
            name: 'ShopCatalog',
            component: ShopCatalog
        },

        {
            path: '/shop-admin/orders/:id/',
            name: 'Orders',
            component: Orders
        },

        {
            path: '/shop-admin/order/view/:id/details/',
            name: 'OrderDetails',
            component: OrderDetails
        },
        {
            path: '*',
            name: '404',
            component: () => import('../views/auth/404.vue')
        }
    ]
};
