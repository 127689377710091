const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

const categories = {
    create: baseUrl + 'categories/create/',
    update: baseUrl + 'categories/update/',
    delete: baseUrl + 'categories/delete/',
    list: baseUrl + 'categories/all/',
    dropdownlist: baseUrl + 'categories/dropdownlist/'
};
const auth = {
    login: baseUrl + 'users/login/',
    status: baseUrl + 'users/loginstatus/',
    ChangePassword: baseUrl + 'users/setPassword'
};

const cart = {
    cartdetails: baseUrl + 'cart/carddetails/',
    removeCart: baseUrl + 'cart/remove/',
    addCard: baseUrl + 'cart/addtoCart/'
};
const userRoles = {
    dropdownlist: baseUrl + 'userroles/dropdownlist'
};
const users = {
    create: baseUrl + 'users/create/',
    update: baseUrl + 'users/update/',
    delete: baseUrl + 'users/delete/',
    list: baseUrl + 'users/all/'
};
const userAddress = {
    create: baseUrl + 'useraddress/create/',
    update: baseUrl + 'useraddress/update/',
    delete: baseUrl + 'useraddress/delete/',
    list: baseUrl + 'useraddress/all/',
    details: baseUrl + 'useraddress/userdetails/'
};
const product = {
    create: baseUrl + 'product/create/',
    update: baseUrl + 'product/update/',
    delete: baseUrl + 'product/delete/',
    list: baseUrl + 'product/all/'
};
const productImages = {
    create: baseUrl + 'product/addproductimage/',
    delete: baseUrl + 'product/removeproductimage/',
    list: baseUrl + 'product/productimagelist/'
};
const shop = {
    create: baseUrl + 'shop/create/',
    update: baseUrl + 'shop/update/',
    delete: baseUrl + 'shop/delete/',
    list: baseUrl + 'shop/all/',
    get: baseUrl + 'shop/getbyid/',
    EnableOrDisable: baseUrl + 'shop/enabledisable/',
    ShopListForUsers: baseUrl + 'shop/shoplistusers'
};
const shopProduct = {
    create: baseUrl + 'shopproduct/create/',
    update: baseUrl + '',
    delete: baseUrl + 'shopproduct/delete/',
    list: baseUrl + 'shopproduct/shopdetails/',
    productlist: baseUrl + 'shopproduct/productfilterlist/',
    EnableOrDisable: baseUrl + 'shopproduct/disable/',
    shopproductlist: baseUrl + 'shopproduct/shopproductlist/',
    shopdetailsadmin: baseUrl + 'shopproduct/shopdetailsadmin/'
};
const shopOrder = {
    addtoorder: baseUrl + 'orders/addtoorderdetails/',
    addtoorder1: baseUrl + 'orders/addtoOrderItems/',
    update: baseUrl + 'orders/updatestatus/',
    delete: baseUrl + '',
    list: baseUrl + 'orders/listorder',
    orderdetails: baseUrl + 'orders/orderdetails',
    placeorder: baseUrl + 'orders/placeorder',
    RemoveItem: baseUrl + 'orders/removecart'
};
export default {
    categories,
    userRoles,
    users,
    userAddress,
    product,
    productImages,
    shop,
    shopProduct,
    shopOrder,
    auth,
    cart
};
