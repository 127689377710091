import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import argon from './plugins/dashboard-plugin';

import components from './plugins/components';

import 'font-icons/nunito/nunito.css';
import 'font-icons/simple-line-icons/css/simple-line-icons.css';
import 'font-icons/iconsmind-s/css/iconsminds.css';
import 'font-icons/nucleo/css/nucleo.css';

import './assets/argon.scss';
import './assets/federal-bank.scss';

import './assets/CSS/custom4.css';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import vuePerfectScrollbar from 'vue-perfect-scrollbar';
import VueScrollTo from 'vue-scrollto';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.component('vue-perfect-scrollbar', vuePerfectScrollbar);
Vue.use(VueScrollTo);
Vue.use(components);
Vue.use(argon);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
