// Guards //
import guards from './guards';

// Layout //
const Layout = () => import('../layouts/Consumer/DashboardLayout');

// Default //
const ShopCatalog = () => import('../views/Consumer/Shops/ShopCatalog');
const UserDetails = () => import('../views/Consumer/UserDetails');
const CartDetails = () => import('../views/Consumer/cart/CartDetails');

// custom //

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/user/shops/:id/products/',
            name: 'ShopCatalog',
            component: ShopCatalog
        },

        {
            path: '/user/details/:id/',
            name: 'UserDetails',
            component: UserDetails
        },
        {
            path: '/user/cart/:id/',
            name: 'CartDetails',
            component: CartDetails
        },
        {
            path: '/user/:id/address-list/',
            name: 'AddressDetails',
            component: () => import('../views/Consumer/Address/AddressList')
        },
        {
            path: '/success/',
            name: 'Success',
            component: () => import('../views/Consumer/Success/SuccessPage')
        },
        {
            path: '*',
            name: '404',
            component: () => import('../views/auth/404.vue')
        }
    ]
};
